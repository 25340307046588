//Importeren van React
import React from "react";

//Importeren Reactdom
import ReactDOM from "react-dom";

//Importeer component
import App from "./App"

//Schiet component in reactdom

ReactDOM.render(<App />, document.getElementById("root"));
