import "./Footer.css"
import React from 'react'

const Footer = () => {
         return(
                  <footer className="footer">
                           <ul>
                                    <li><a href="/">Home</a></li>
                                    {/* <li><a href="/">About</a></li>
                                    <li><a href="/">Projecten</a></li> */}
                           </ul>
                  </footer>
         )
}

export default Footer